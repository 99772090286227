.top-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--size-16);
    padding: var(--size-16);

    .button {
        color: var(--color-black);
        padding: var(--size-4) var(--size-12);
        border-radius: var(--size-16);
        cursor: pointer;
        border: none;

        &.edit-mode {
            color: var(--color-primary);
            background-color: var(--backgound-transparent);
            padding: var(--size-4) 0;
        }
    }

    .abort-button {
        @extend .button;
        background-color: var(--color-grey);
    }

    .submit-button {
        @extend .button;
        background-color: var(--color-yellow);
    }

    .edit-text {
        color: var(--color-grey);
    }
}