.edit-image-box {
    position: absolute;
    overflow: hidden;
    transition: all .15s ease-out;
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    background-color: var(--color-black);
}