.model-header {
    border-bottom: 1px solid var(--color-border);
    padding: var(--size-18) var(--size-18);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .icon {
        padding-right: var(--size-16);
    }

    .header-content {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: var(--font-weight-medium);
            font-size: var(--size-16);
        }
    }
}