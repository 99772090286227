.menu {
    margin: var(--size-8) 0 0 0;
    padding: 0;
    text-align: center;
    background-color: var(--color-white);
    border-radius: var(--border-radius);

    li {
        display: block;
        text-align: left;
        padding-top: var(--size-16);
        padding-bottom: var(--size-16);
        margin-left: var(--size-16);
        margin-right: var(--size-16);
        cursor: pointer;
        border-bottom: 1px solid var(--color-border);
        font-weight: var(--font-weight-medium);

        &:last-child {
            text-align: center;
            border-bottom: 1px solid transparent;
        }
    }

    .edit, .close {
        color: var(--color-button-border);
        .icon {
            padding-right: var(--size-10);
        }
    }

    .abortBtn {
        color: var(--color-white);
        background-color: var(--color-button-border);
    }
}