.confirmation-menu {
    background: var(--backgound-transparent);
    border-top-left-radius: var(--size-14);
    border-top-right-radius: var(--size-14);
    margin-bottom: var(--size-40);
    
    .model-header {
        text-align: center;
        background-color: var(--confirm-menu-background);
        border-bottom: var(--size-1) solid var(--modal-border);
        padding: var(--size-30) var(--size-40) var(--size-8);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .header-content {
            position: relative;
            display: flex;
            flex-direction: column;

            .title {
                color: var(--color-background-off-white);
                font-size: var(--size-16);
                word-break: break-word;
                overflow-wrap: break-word;
                white-space: normal;
            }
        }
    }

    .modal-body {
        text-align: center;

        .actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--size-8);

            .action-btn {
                font-size: var(--size-20);
                font-weight: var(--font-weight-regular);
                background-color: var(--confirm-menu-background);
                padding: var(--size-16) var(--size-16);
                border: none;
                cursor: pointer;
    
                &.confirm-btn {
                    color: var(--confirm-btn-color);
                    border-radius: 0 0 var(--size-14) var(--size-14);
                }
    
                &.cancel-btn {
                    color: var(--color-background-off-white);
                    border-radius: var(--size-14);
                }
            }
        }
    }
}