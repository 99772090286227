.group {
    .radio-check {
      border-radius: 0;
  
      &:first-child {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
      }
      &:last-child {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }
      &:not(:last-child) {
        border-bottom: none;
      }
    }
  }
  