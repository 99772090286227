:root {
  // Font
  --font-family-primary: 'Roboto', sans-serif;
  --font-family-arial: Arial, Helvetica, sans-serif;
  --font-family-primary-mono: 'Roboto Mono';
  --font-size: 14px;
  --font-line-height: 1.142857142857143;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-medium-strong: 600;

  // Colors
  --color-font: rgba(0, 0, 0, .87);
  --color-font-grey: rgba(0, 0, 0, .54);
  --color-font-light: rgba(0, 0, 0, .28);
  --color-font-light-gray: rgba(0, 0, 0, .40);
  --color-font-title: #0000008A;
  --color-grey: #999999;
  --color-header: #000000;
  --color-header-a30: #c4c7cc;
  --color-body: #f1f1f1;
  --color-primary: #0d71e0;
  --color-border: #dedfdf;
  --color-card-background: rgba(0, 0, 0, .12);
  --color-button-border: #47576a;
  --color-box-shadow: rgba(0, 0, 0, .12);
  --color-box-shadow-big: rgba(0, 0, 0, .16);
  --color-dimmer: rgba(0, 0, 0, .5);
  --color-red: #ee1620;
  --color-red-secondary: #ff6667;
  --color-red-monza: #d4092c;
  --color-red-shine: #F05350;
  --color-green: #21ba45;
  --color-green-dark: #11802B;
  --color-green-spring: #76C836;
  --color-green-secondary: #EFFFE3;
  --color-yellow: #ffc400;
  --color-offwhite: #cccccc;
  --color-white: #ffffff;
  --color-alto: #D0D0D0;
  --color-black: #000000;
  --color-black-secondary: #000000DE;
  --color-white-secondary: #FFFFFFDE;
  --color-light-blue: #0064A3;
  --color-messages-body: #F9F9F9;
  --color-status-minor: #FFF5BE;
  --color-status-critical: #FFD7BE;
  --color-status-more-critical: #FFBEBE;
  --color-status-repair: #F2F2F2;
  --color-background-off-white: #FAFAFA;
  --color-status-register: #DB2828;
  --color-status-completed: #2F9E47;
  --color-status-inprogress: #FFAC01;
  --color-status-closed: rgb(0, 175, 255);
  --color-status-inprogress-background: #FFF5BE;
  --color-status-finished-background: #EFFFE3;
  --color-status-closed-background: rgb(184 232 255);
  --color-hover: rgba(0, 0, 0, .04);

  // Statuses
  --color-statuse-cancelled: rgba(0, 0, 0, .38);

  //   Request
  --request-statuse-draft: #db2828;
  --request-statuse-submitted: #3b4755;
  --request-statuse-approved: #0466a4;
  --request-statuse-rejected: #ee1620;
  --request-statuse-cancelled: #3b4755;
  --request-statuse-in-planning: #fe7c06;
  --request-statuse-completed: #0d5065;

  //   Orders
  --order-status-in-planning: #fe7c06;
  --order-status-approved: #0466a4;
  --order-status-cancelled: #3b4755;
  --order-status-completed: #0d5065;

  // flash messages background
  --flash-yellow: #FFE036;
  --flash-blue: #3C84D9;
  --flash-red: #DB5C36;
  --flash-red-dark: #DB3636;
  --flash-green: #21ba45;
  --flash-yellow-gold: #FFB400;

  // Sizes
  @for $i from 1 through 90 {
    --size-#{$i}: #{$i / 14 * 1rem};
  }

  --main-menu-width: 280px;

  // Z-index
  --z-index-dropdown: 5;
  --z-index-modal: 100;
  --z-index-overlay: 200;
  --z-index-main-menu: 25;
  --z-index-main-menu-overlay: 20;

  // Others
  --border: 1px solid var(--color-border);
  --border-radius: .2857142857142857rem;
  --border-radius-50: 50%;
  --box-shadow: 0 2px 4px var(--color-box-shadow);
  --box-shadow-big: 0 3px 6px var(--color-box-shadow-big);

  --message-unread-status: #F2F8FE;

  // Dashboard
  --active-tab-border: #FF6833;
  --dashboard-card-border: #E0E0E0;
  --color-high-performance: #24A148;
  --color-average-performance: #F1C21B;
  --color-low-performance: #dA1E28;
  --color-secondary-green-target-area: #edf7f0;
  --color-blue-dasboard-data: #0043CE; 
  --dashbaord-primary-text: #161616;
  --dashbaord-secondary-text: #525252;
  --see-trend: #0F62FE;
  --error-message-background: #FFE1D6;
  --error-message-text: #CC5329;
  --letter-spacing-regular: 0.16px;
  --letter-spacing-medium: 0.32px;

  // Image Editor
  --backgound-transparent: transparent;
  --active-undo-redo: #0A84FF;
  --edit-image-icon-color: #E1E1E1;
  --edit-image-icon-background: #1B1B1B;

  // Color Picker
  --modal-background: #1C1C1C;
  --modal-close-icon-background: #383838;
  --modal-close-icon: #AFAFAF;
  --modal-text: #E2E2E2;
  --modal-border: #313131;

  // Confirmation Menu
  --confirm-menu-background: #121212FA;
  --confirm-btn-color: #ff453a; 
}
