.details {
    .input-container {
        margin: var(--size-16) 0;

        textarea {
            font-size: var(--size-14);
            min-height: var(--size-90);
        }
    }

    .radio-check {
        padding: var(--size-8) 0;
        border: none;
        font-size: var(--size-14);

        i {
            padding-right: var(--size-12);
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}