.color-picker {
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--modal-background);
  padding: var(--size-20) var(--size-16) var(--size-54) var(--size-16);
  border-top-left-radius: var(--size-14);
  border-top-right-radius: var(--size-14);

  &.bottom {
    bottom: 0;
  }
  
  .model-header {
    border-bottom: var(--size-1) solid var(--modal-border);
    padding-bottom: var(--size-18);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .header-content {
      position: relative;
      display: flex;
      flex-direction: column;

      .title {
        color: var(--modal-text);
        font-size: var(--size-16);
      }

    }

    .close-icon {
      position: absolute;
      right: 4%;
      color: var(--modal-close-icon);
      background-color: var(--modal-close-icon-background);
      border-radius: var(--border-radius-50);
      padding: var(--size-4);
      cursor: pointer;
    }
  }

  .modal-body {
    padding-top: var(--size-24);
    text-align: center;

    .color-palette {
      display: flex;
      justify-content: space-evenly;
      gap: var(--size-10);

      .color {
        width: var(--size-32);
        height: var(--size-32);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border: none;

        &.selected::after {
          content: "";
          position: absolute;
          width: calc(var(--size-32) - var(--size-6)); // Slightly larger than the button
          height: calc(var(--size-32) - var(--size-6));
          background-color: transparent;
          border-radius: 50%;
          border: var(--size-2) solid var(--color-black);
        }
      }
    }
  }
}