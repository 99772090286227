.edit-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--color-grey);
    padding: var(--size-24) var(--size-16);
    width: 100%;

    .delete-icon {
        border: var(--size-2) solid var(--color-grey);
        padding: var(--size-2);
        border-radius: var(--border-radius-50);
        cursor: pointer;
    }

    .history-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--size-24);

        .undo-icon,
        .redo-icon {
            border: var(--size-2) solid var(--color-grey);
            padding: var(--size-2);
            border-radius: var(--border-radius-50);

            &.active {
                color: var(--active-undo-redo);
                border-color: var(--active-undo-redo);
                cursor: pointer;
            }
        }
    }

    .drawing-controls {
        display: flex;
        align-items: center;
        gap: var(--size-24);

        .edit-icon {
            border: var(--size-2) solid var(--color-grey);
            color: var(--color-white);
            padding: var(--size-10);
            border-radius: var(--border-radius-50);
            cursor: pointer;

            &.edit-mode {
                color: var(--color-black);
                background-color: var(--color-grey);
            }
        }

        .current-color {
            width: var(--size-24);
            height: var(--size-24);
            border-radius: 50%;
            border: var(--size-2) solid var(--color-white);
            cursor: pointer;
        }
    }

    // If there are no undo/redo and delete icons, align the edit icon to the right
    &:not(:has(.history-controls, .delete-icon)) {
        justify-content: flex-end;
    }
}