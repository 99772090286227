.file-preview-container {
  display: flex;
  flex-direction: row;
  padding: var(--size-12) 0;
  overflow-x: auto;
  .preview {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    img {
      height: 99px;
      max-width: fit-content;
    }

    .actions {
      display: flex;
      align-items: center;
      position: relative;
      left: -36px;
      top: -13px;
      
      // When .edit is not available (or empty)
      &:not(:has(.edit)) {
        top: -14px; // Adjust the top position when .edit is not available
        left: -16px; // Adjust the left position as needed
      }
      .edit {
        color: var(--edit-image-icon-color);
        border-radius: 50%;
        padding: var(--size-4);
        background: var(--edit-image-icon-background);
        font-size: var(--size-12);
      }
  
      .delete {
        color: var(--color-red-monza);
        font-size: var(--size-24);
        padding-left: var(--size-2);
      }
    }
      
  }
}