.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: var(--size-16) var(--size-16) var(--size-16) var(--size-16);
    justify-content: space-between;
    border-top: 1px solid var(--color-border);

    .abort {
        padding: var(--size-12) 0;
        width: calc(35% - 6px);
        font-weight: var(--font-weight-medium);
        cursor: pointer;
    }

    .submit {
        width: calc(65% - 6px);
        padding: var(--size-12) 0;
        font-weight: var(--font-weight-medium);
        background-color: var(--color-primary);
        color: var(--color-white);
        border-color: var(--color-primary);
        opacity: 1;
        cursor: pointer;

        &:disabled {
            opacity: 0.4;
            cursor: no-drop;
        }
    }
}