.messages-container {
  background: var(--color-white);
  padding: var(--size-4) var(--size-16);

  .title {
    font-weight: var(--font-weight-medium);
    padding-bottom: var(--size-12);
  }

  .message-count {
    margin-left: var(--size-12);
    font-weight: var(--font-weight-regular);
    color: var(--color-font-grey);
  }
}