.icon {
    cursor: pointer;
}

.button {
    width: 100%;
    display: flex;
    align-content: space-between;
    bottom: 0;
    flex-direction: row;
    font-size: var(--size-16);
    justify-content: space-between;
    padding: var(--size-4) var(--size-16) var(--size-16) var(--size-16);
    background: var(--color-white);

    .closeIssue {
        width: 100%;
        padding: var(--size-12) 0;
        color: var(--color-white);
        background-color: var(--color-red);
        border-color: var(--color-red);
        font-weight: var(--font-weight-medium);
        cursor: pointer;
    }
}
